import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/dashboard";

export function ActionLoadDashboard(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.DASHBOARD_LOADING, payload: true });
    http.get(`/api/v1/adm/dashboard/listar`).then(
      (response) => {
        store.dispatch([
          { type: types.DASHBOARD_DATA, payload: response.data },
          { type: types.DASHBOARD_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.DASHBOARD_LOADING, payload: false });
        reject(e);
      }
    );
  });
}