import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useState, useEffect } from "react";
import { ListarTodosVendedores } from "store/actions/vendedores";
import { CadastrarFunilVendaDia } from "store/actions/vendedores";

const { default: ModalComponent } = require("components/Modal");

function CadastrarVendas({ open, data, close }) {
  console.log(data)

  const [vendedorData, setVendedorData] = useState(data);

  useEffect(() => {
    setVendedorData(data);
  }, [data]);

  const handleInputChange = (index, field, value) => {
    const updatedVendas = vendedorData.vendas_dia.map((venda, i) =>
      i === index ? { ...venda, [field]: value } : venda
    );
    setVendedorData({ ...vendedorData, vendas_dia: updatedVendas });
  };

  async function handleSave() {
    try {
      console.log(vendedorData.vendas_dia)
      const dataToSave = {
        vendedor_id: vendedorData.vendedor_id,
        vendas: vendedorData.vendas_dia.map((venda) => {
          return {
            linhas: Number(venda.total_linhas),
            receita: Number(venda.total_receita),
            fator_operadora: venda.id,
          };
        }),
      };
      
      // Enviar dados para cadastrar no funil
      await CadastrarFunilVendaDia(dataToSave);
  
      // Listar todos os vendedores após o cadastro
      await ListarTodosVendedores();
  
      // Fechar após operações bem-sucedidas
      close();
    } catch (error) {
      console.error("Erro ao salvar:", error);
      // Lidar com o erro, se necessário
    }
  }

  return (
    <ModalComponent open={open} width="auto">
      <h4>{vendedorData.vendedor_nome}</h4>
      {vendedorData.vendas_dia?.map((vendas, index) => (
        <div key={vendas.id || index}>
          {vendas.fator_nome}
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <MDInput
                type="text"
                label="Total"
                fullWidth
                value={vendas.total_linhas}
                onChange={(e) =>
                  handleInputChange(index, "total_linhas", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <MDInput
                type="text"
                label="Receita"
                fullWidth
                value={vendas.total_receita}
                onChange={(e) =>
                  handleInputChange(index, "total_receita", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      ))}
      <MDButton variant="gradient" color="info" fullWidth onClick={handleSave}>
        Salvar
      </MDButton>
    </ModalComponent>
  );
}

export default CadastrarVendas;
