import { Divider, Grid } from "@mui/material";
import AlertComponent from "components/AlertComponent";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import GenericRadioButton from "components/RadioButton";
import { formatCelular } from "helpers";
import { formatCPF } from "helpers";
import FormatBirth from "helpers/formatBirth";
import formatarDataNascimentoDB from "helpers/formatBirthDataBase";
import formatName from "helpers/formatName";
import { removeDots } from "helpers/removeDots";
import { useEffect, useState } from "react";
import { ActionCadatrarVendedor } from "store/actions/vendedores";

const { default: ModalComponent } = require("components/Modal");

function VendedoresCadastro({ open, close, updateList }) {
  const [clienteData, setClienteData] = useState({
    nome: "",
    cpf: "",
    email: "",
    celular: "",
    custo_total: 0.00,
    sexo: "",
    custo_total: "",
    data_nascimento: "",
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");

  const [novoCadastro, setNovoCadastro] = useState(false);

  useEffect(() => {
    const { nome } = clienteData;
    const isFilled = nome;
    setNovoCadastro(isFilled);
  }, [clienteData]);

  function CloseModalFnc() {
    close();
    updateList();
    setClienteData({
      nome: "",
    });
  }

  async function EfetuarCadastroFNC() {
    const data = {
      ...clienteData,
      data_nascimento: formatarDataNascimentoDB(clienteData.data_nascimento),
      cpf: removeDots(clienteData.cpf),
      celular: removeDots(clienteData.celular),
    };

    try {
      await ActionCadatrarVendedor(data).then((res) => {
        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Cliente cadastrado com sucesso: ${clienteData.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
        CloseModalFnc();
      });
    } catch (e) {
      setShowSuccessAlert(true);
      setTypeMessage("error");
      setDataMessage(
        e.response ? e.response.data.message : "Erro ao efetuar cadastro"
      );
      setTimeout(() => {
        setShowSuccessAlert(false);
        setTypeMessage("");
        setDataMessage("");
      }, 3000);
    }
  }

  const styleClos = {
    backgroundColor: "transparent",
    color: "red",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 12,
  };

  const genderOptions = [
    { value: "M", label: "Masculino" },
    { value: "F", label: "Feminino" },
    { value: "I", label: "Não informar" },
    // Você pode adicionar mais opções conforme necessário
  ];

  const handleGenderChange = (event) => {
    setClienteData({ ...clienteData, sexo: event.target.value });
  };

  return (
    <>
      <ModalComponent open={open} width="40%">
        {showSuccessAlert && (
          <AlertComponent
            type={typeMessage}
            message={dataMessage}
            timeout={3000}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="h6">Cadastro de vendedor</MDTypography>
          <button style={{ ...styleClos }} onClick={CloseModalFnc}>
            FECHAR X
          </button>
        </div>
        <span style={{ fontSize: 12 }}>Digite os dados para cadastro</span>
        <Divider />

        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <MDInput
              type="text"
              label="Nome"
              fullWidth
              value={clienteData.nome ? formatName(clienteData.nome) : ""}
              onChange={(e) =>
                setClienteData({ ...clienteData, nome: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <MDInput
              type="text"
              label="CPF"
              fullWidth
              value={clienteData.cpf ? formatCPF(clienteData.cpf) : ""}
              onChange={(e) =>
                setClienteData({ ...clienteData, cpf: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              placeholder="email@email.com"
              value={clienteData.email}
              onChange={(e) =>
                setClienteData({ ...clienteData, email: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <MDInput
              type="text"
              label="Celular"
              fullWidth
              placeholder="(00)00000-0000"
              value={formatCelular(clienteData.celular)}
              onChange={(e) =>
                setClienteData({
                  ...clienteData,
                  celular: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <MDInput
              fullWidth
              type="text"
              label="Data de Nascimento"
              placeholder="DD/MM/AAAA"
              value={FormatBirth(clienteData.data_nascimento)}
              onChange={(e) =>
                setClienteData({
                  ...clienteData,
                  data_nascimento: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <MDInput
              type="text"
              label="Custo Total"
              fullWidth
              value={clienteData.custo_total}
              onChange={(e) =>
                setClienteData({
                  ...clienteData,
                  custo_total: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <GenericRadioButton
              // label="Sexo"
              name="gender"
              value={clienteData.sexo}
              options={genderOptions}
              onChange={handleGenderChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={EfetuarCadastroFNC}
              disabled={!novoCadastro}
            >
              Cadastrar
            </MDButton>
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
}

export default VendedoresCadastro;
