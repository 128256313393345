import * as types from "../types/status_pedidos";

const INITIAL_STATE = {
  loading: false,
  status_pedidos: [],
  status_pedido: {},
  page: 1,
  per_page: 10,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.STATUS_PEDIDOS_LOADING:
      return { ...state, loading: action.payload };

    case types.STATUS_PEDIDOS_DATA:
       return { ...state, status_pedidos: action.payload };

    case types.STATUS_PEDIDO_DATA:
       return { ...state,status_pedido: action.payload };

       case types.STATUS_PEDIDOS_DATA_PAGE:
        return { ...state, page: action.payload };
  
      case types.STATUS_PEDIDOS_DATA_PER_PAGE:
        return { ...state, per_page: action.payload };
  
      case types.STATUS_PEDIDOS_DATA_TOTAL:
        return { ...state, total: action.payload };


    default:
      return state;
  }
};

