import { applyMiddleware, combineReducers, createStore } from "redux";
import multi from "redux-multi";
import thunk from "redux-thunk";

import { dashboard, operadora_fator, status_pedidos, usuario, vendedores } from "./reducers";

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reducers = combineReducers({
  usuario,
  vendedores,
  status_pedidos,
  operadora_fator,
  dashboard
});

export default applyMiddleware(thunk, multi)(createStore)(
  reducers,
  process.env.NODE_ENV === "development" ? devTools : undefined
);
