import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useState, useEffect } from "react";
import { CadastrarFunilGeral } from "store/actions/status_pedidos";

const { default: ModalComponent } = require("components/Modal");

function CadastrarVendas({ open, data, close, reload }) {
  const [vendedorData, setVendedorData] = useState(data);

  useEffect(() => {
    setVendedorData(data);
  }, [data]);

  const handleInputChange = (index, field, value) => {
    const updatedVendas = vendedorData.fator_operadora.map((venda, i) =>
      i === index ? { ...venda, [field]: value } : venda
    );
    setVendedorData({ ...vendedorData, fator_operadora: updatedVendas });
  };

  async function handleSave() {
    try {
      const dataToSave = {
        vendas: [
          {
            status_pedido_id: vendedorData.id,
            fator_vendas: vendedorData.fator_operadora.map((venda) => {
              return {
                linhas: Number(venda.linhas),
                receita: Number(venda.receita),
                fator_operadora: venda.id,
              };
            }),
          },
        ],
      };
      // Enviar dados para cadastrar no funil
      await CadastrarFunilGeral(dataToSave);

      reload()
      // Fechar após operações bem-sucedidas
      close();
    } catch (error) {
      console.error("Erro ao salvar:", error);
      // Lidar com o erro, se necessário
    }
  }

  return (
    <ModalComponent open={open} width="auto">
      <h4>{vendedorData.status_nome}</h4>

      {vendedorData.fator_operadora?.map((vendas, index) => (
        <div key={vendas.id || index}>
          {vendas.nome}
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <MDInput
                type="text"
                label="Total"
                fullWidth
                value={vendas.linhas}
                onChange={(e) =>
                  handleInputChange(index, "linhas", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <MDInput
                type="text"
                label="Receita"
                fullWidth
                value={vendas.receita}
                onChange={(e) =>
                  handleInputChange(index, "receita", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      ))}
      <br />
      <MDButton variant="gradient" color="info" fullWidth onClick={handleSave}>
        Salvar
      </MDButton>
    </ModalComponent>
  );
}

export default CadastrarVendas;
