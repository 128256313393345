// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import styled from "@emotion/styled";
import { Assessment } from "@mui/icons-material";
import { Card, Icon } from "@mui/material";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toCurrency } from "helpers/toCurrency";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionLoadDashboard } from "store/actions/dashboard";

function Dashboard() {
  const navigate = useNavigate();
  const { dashboard } = useSelector((state) => state.dashboard);
  const [dataBase, setDataBase] = useState({});

  console.log(dashboard.vendas_mes);

  useEffect(() => {
    ActionLoadDashboard();
  }, []);

  const ButtonSeeMore = styled.button`
    color: gray;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border: none;
  `;

  function somaTotalFaturamento(data) {
    return (
      data &&
      toCurrency(data.reduce((acc, item) => acc + item.total_faturamento, 0))
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h4>Vendas mês</h4>
      <MDBox py={3}>
        <Grid container spacing={3}>
          {dashboard?.vendas_mes?.map((vendas, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={index.fator_operadora}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="persons"
                    title={vendas.fator_nome}
                    count={toCurrency(vendas.total_receita)}
                    percentage={{
                      label: (
                        <div>
                          Faturamento {toCurrency(vendas.total_faturamento)} |
                          Linhas {vendas.total_linhas}
                        </div>
                      ),
                    }}
                  />
                </MDBox>
              </Grid>
            );
          })}
        </Grid>
        Previsao de Vendas{" "}
        {dashboard && somaTotalFaturamento(dashboard?.vendas_mes)}
      </MDBox>
      <h4>Pedidos mês</h4>

      <MDBox py={3}>
        <Grid container spacing={3}>
          {dashboard?.status?.map((status, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={index.id}>
                <br />
                <Card>
                  <MDBox
                    style={{ backgroundColor: "#661177" }}
                    variant="gradient"
                    bgColor="red"
                    color="dark"
                    coloredShadow="red"
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3}
                  >
                    <Icon
                      fontSize="medium"
                      color="white"
                      style={{ color: "#FFF" }}
                    >
                      <Assessment />
                    </Icon>
                  </MDBox>
                  <h4 style={{ margin: 15 }}>{status.status_nome}</h4>
                  <MDBox mb={1.5} style={{ padding: 5 }}>
                    <Grid
                      container
                      spacing={3}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      <Grid item xs={4} md={4} lg={4}>
                        Nome
                      </Grid>
                      <Grid item xs={2} md={2} lg={2}>
                        Qtd
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        Receita
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        Fat.
                      </Grid>
                    </Grid>
                    {status?.fator_operadora?.map((fator, _index) => {
                      return (
                        <Grid
                          container
                          spacing={3}
                          key={_index.id}
                          style={{ fontSize: 12 }}
                        >
                          <Grid item xs={4} md={4} lg={4}>
                            {fator.nome}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            {fator.linhas}
                          </Grid>
                          <Grid item xs={3} md={3} lg={3}>
                            {toCurrency(fator.receita)}
                          </Grid>
                          <Grid item xs={3} md={3} lg={3}>
                            {toCurrency(fator.total_faturamento)}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <div style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'right', marginTop: 7 }}>
                      Total Fat. &nbsp;
                      {status && somaTotalFaturamento(status?.fator_operadora)}
                    </div>
                  </MDBox>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
      {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
