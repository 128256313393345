// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Tooltip } from "@mui/material";
import Loading from "components/Loading";

import * as types from "../../store/types/status_pedidos";
import OperadoraFatorEditar from "./cadastrarStatus/edit";
import { ActionAtualizarOperadora } from "store/actions/operadora_fator";
import { ActionLoadStatusPedidos } from "store/actions/status_pedidos";
import StatusPedidoCadastro from "./cadastrarStatus";
import MDButton from "components/MDButton";
import { toCurrency } from "helpers/toCurrency";
import CadastrarVendas from "./cadastrarStatusMensal";

function StatusPedidoScreen() {
  const dispatch = useDispatch();
  const { status_pedidos, loading, page, per_page, total } = useSelector(
    (state) => state.status_pedidos
  );
  const [dataVendedorSelecionado, setDataVendedorSelecionado] = useState({});

  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [resetPage, setResetPage] = useState(false);

  function OpenModalVendededor(data) {
    console.log(data)
    setDataVendedorSelecionado(data);
    setOpenModalVendas(true);
  }

  async function GetDataLoad() {
    const data = {
      page: resetPage ? 1 : page,
      per_page: per_page,
      filtro: filtroAtualizado,
    };

    await ActionLoadStatusPedidos(data);
    setFiltroAtualizado("");
    setResetPage(false);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
        setResetPage(true);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, page]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, page, resetPage]);

  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [dataModalEditar, setDataModalEditar] = useState({});
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");
  const [openModalVendas, setOpenModalVendas] = useState(false);

  const navigatePages = (validations) => {
    dispatch({ type: types.STATUS_PEDIDOS_DATA_PAGE, payload: validations });
  };

  async function AtualizarCategoria(data) {
    try {
      await ActionAtualizarOperadora(data).then(async (res) => {
        await GetDataLoad();

        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Cliente aceito com sucesso: ${data.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
      });
    } catch (e) {
      console.log(e);
    }
  }

  function ModalEditarCategoria(data) {
    setDataModalEditar(data);
    setOpenModalEditar(true);
  }


  return (
    <DashboardLayout>
      <Loading loading={loading} />
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Acompanhamento Mensal
                  </MDTypography>
                  <MDButton onClick={() => setOpenModalCadastro(true)}>
                    CADASTRAR STATUS
                  </MDButton>
                </div>
              </MDBox>
              <Paper elevation={1} style={{ padding: 5 }}>
                Selecione o status para incluir o fechamento mensal:
                <Grid container spacing={1}>
                  {status_pedidos &&
                    status_pedidos?.map((fator, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          key={index.id}
                        >
                          <div
                            style={{
                              border: "1px solid gray",
                              borderRadius: "15px 0px 15px 0px",
                              padding: 7,
                            }}
                          >
                            <h4
                              style={{
                                fontSize: 14,
                                textAlign: "center",
                              }}
                            >
                              {fator.status_nome}
                            </h4>
                            <br/>
                            <Grid
                              container
                              spacing={1}
                              height="100%"
                              style={{ fontSize: 12 }}
                            >
                              <Grid
                                container
                                spacing={1}
                                height="100%"
                                style={{ fontSize: 12 }}
                              >
                                {fator?.fator_operadora?.map((dia) => {
                                  return (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      key={index.vendedor_id}
                                      style={{ padding: 10, color: dia.linhas > 0 ? 'green' : '#0c0c0c' }}
                                   
                                    >
                                      • Produto: <b>{dia.nome}</b> <br />•
                                      Quantidade: {dia.linhas}
                                      <br />• Receita: {toCurrency(dia.receita)}
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                            <MDButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              onClick={() => [
                                OpenModalVendededor(fator),
                                console.log("----->", fator),
                              ]}
                            >
                              Cadastrar
                            </MDButton>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              </Paper>
            </Card>
          </Grid>
        </Grid>
        <StatusPedidoCadastro
          open={openModalCadastro}
          close={() => setOpenModalCadastro(false)}
          updateList={GetDataLoad}
        />
        <OperadoraFatorEditar
          open={openModalEditar}
          close={() => setOpenModalEditar(false)}
          updateList={GetDataLoad}
          data={dataModalEditar}
          setData={setDataModalEditar}
        />

<CadastrarVendas
          data={dataVendedorSelecionado}
          open={openModalVendas}
          close={() => setOpenModalVendas(false)}
          reload={GetDataLoad}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StatusPedidoScreen;
