import { Divider, Grid } from "@mui/material";
import AlertComponent from "components/AlertComponent";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import formatName from "helpers/formatName";
import { useEffect, useState } from "react";
import { ActionCadastrarStatus } from "store/actions/status_pedidos";

const { default: ModalComponent } = require("components/Modal");

function StatusPedidoCadastro({ open, close, updateList }) {
  const [clienteData, setClienteData] = useState({
    nome: "",
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");

  const [novoCadastro, setNovoCadastro] = useState(false);

  useEffect(() => {
    const { nome } = clienteData;
    const isFilled = nome;
    setNovoCadastro(isFilled);
  }, [clienteData]);

  function CloseModalFnc() {
    close();
    updateList();
    setClienteData({
      nome: "",
    });
  }

  async function EfetuarCadastroFNC() {
    try {
      await ActionCadastrarStatus(clienteData).then((res) => {
        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Fator cadastrado com sucesso: ${clienteData.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
        CloseModalFnc();
      });
    } catch (e) {
      setShowSuccessAlert(true);
      setTypeMessage("error");
      setDataMessage(
        e.response ? e.response.data.message : "Erro ao efetuar cadastro"
      );
      setTimeout(() => {
        setShowSuccessAlert(false);
        setTypeMessage("");
        setDataMessage("");
      }, 3000);
    }
  }

  const styleClos = {
    backgroundColor: "transparent",
    color: "red",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 12,
  };

  return (
    <>
      <ModalComponent open={open} width="auto">
        {showSuccessAlert && (
          <AlertComponent
            type={typeMessage}
            message={dataMessage}
            timeout={3000}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="h6">
            Cadastro de Status
          </MDTypography>
          <button style={{ ...styleClos }} onClick={CloseModalFnc}>
            FECHAR X
          </button>
        </div>
        <span style={{ fontSize: 12 }}>Digite os dados para cadastro</span>
        <Divider />

        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <MDInput
              type="text"
              label="Nome do Status"
              fullWidth
              value={clienteData.nome ? formatName(clienteData.nome) : ""}
              onChange={(e) =>
                setClienteData({ ...clienteData, nome: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={EfetuarCadastroFNC}
              disabled={!novoCadastro}
            >
              Cadastrar
            </MDButton>
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
}

export default StatusPedidoCadastro;
