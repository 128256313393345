const formatCelular = (telefone) => {
  // Remove todos os caracteres não numéricos

  if(telefone){
    const numericPhone = telefone?.replace(/\D/g, '');

    // Formata o número de telefone
    let formattedPhone = "";
  
    if (numericPhone?.length <= 2) {
      formattedPhone = `${numericPhone}`;
    } else if (numericPhone?.length <= 7) {
      formattedPhone = `${numericPhone.substring(0, 2)} ${numericPhone.substring(2)}`;
    } else if (numericPhone?.length <= 11) {
      formattedPhone = `(${numericPhone.substring(0, 2)}) ${numericPhone.substring(2, 7)}-${numericPhone.substring(7)}`;
    } else {
      formattedPhone = `(${numericPhone.substring(0, 2)}) ${numericPhone.substring(2, 7)}-${numericPhone.substring(7, 11)}`;
    }
  
    return formattedPhone;
  }

};

export default formatCelular;
