import { Divider, Grid } from "@mui/material";
import AlertComponent from "components/AlertComponent";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import formatName from "helpers/formatName";
import { useEffect, useState } from "react";
import { ActionAtualizarVendedor } from "store/actions/vendedores";

const { default: ModalComponent } = require("components/Modal");

function VendedoresEditar({ open, close, updateList, data, setData }) {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");


  function CloseModalFnc() {
    close();
    updateList();
    setData({});
  }

  async function EfetuarCadastroFNC() {
    try {
      await ActionAtualizarVendedor({nome: formatName(data.nome), categoria_id: data.id }).then((res) => {
        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Cliente atualizado com sucesso: ${data?.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
        CloseModalFnc();
      });
    } catch (e) {
      setShowSuccessAlert(true);
      setTypeMessage("error");
      setDataMessage(e.response ? e.response.data.message : "Erro ao efetuar cadastro");
      setTimeout(() => {
        setShowSuccessAlert(false);
        setTypeMessage("");
        setDataMessage("");
      }, 3000);
    }
  }

  const styleClos = {
    backgroundColor: "transparent",
    color: "red",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 12,
  };

  return (
    <>
      <ModalComponent open={open} width="30%">
        {showSuccessAlert && (
          <AlertComponent type={typeMessage} message={dataMessage} timeout={3000} />
        )}

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <MDTypography variant="h6">Editar Categoria</MDTypography>
          <button style={{ ...styleClos }} onClick={CloseModalFnc}>
            FECHAR X
          </button>
        </div>
        <span style={{ fontSize: 12 }}>Digite o nome da categoria, ela será exibida no APP</span>
        <Divider />

        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <MDInput
              type="text"
              label="Nome"
              fullWidth
              value={data.nome}
              onChange={(e) => setData({ ...data, nome: e.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={EfetuarCadastroFNC}
            >
              Cadastrar
            </MDButton>
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
}

export default VendedoresEditar;
