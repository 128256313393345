// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Paper, Tooltip } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import Loading from "components/Loading";
import styled from "@emotion/styled";
import MDBadge from "components/MDBadge";
import {
  CancelOutlined,
  DoDisturb,
  DoneOutline,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import * as types from "../../store/types/operadora_fator";
import { EditOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import OperadoraFatorCadastro from "./vendasDiariaCadastro";
import OperadoraFatorEditar from "./vendasDiariaCadastro/edit";
import { loadOperadora } from "store/actions/operadora_fator";
import { ActionAtualizarOperadora } from "store/actions/operadora_fator";
import { ListarTodosVendedores } from "store/actions/vendedores";
import { toCurrency } from "helpers/toCurrency";
import MDButton from "components/MDButton";
import CadastrarVendas from "./cadastrarVendas";

function VendasDiariasScreen() {
  const dispatch = useDispatch();
  const { operadora_fator, loading, page, per_page, total } = useSelector(
    (state) => state.operadora_fator
  );
  const { vendedores_totais } = useSelector((state) => state.vendedores);

  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [resetPage, setResetPage] = useState(false);

  useEffect(() => {
    ListarTodosVendedores();
  }, []);


  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [dataModalEditar, setDataModalEditar] = useState({});
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");

  const navigatePages = (validations) => {
    dispatch({ type: types.OPERADORA_DATA_PAGE, payload: validations });
  };

  async function AtualizarCategoria(data) {
    try {
      await ActionAtualizarOperadora(data).then(async (res) => {
        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Cliente aceito com sucesso: ${data.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
      });
    } catch (e) {
      console.log(e);
    }
  }

  function ModalEditarCategoria(data) {
    setDataModalEditar(data);
    setOpenModalEditar(true);
  }

  const ButtonStyle = styled.button`
    background: #002244;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid gray;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background: #2c3e50;
      transition: 0.3s ease-in-out;
    }
    &:active {
      background: #2c3e50;
      top: 2px;
    }
    &:before {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      line-height: 3;
      font-size: 140%;
      width: 60px;
    }
  `;

  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  const [dataVendedorSelecionado, setDataVendedorSelecionado] = useState({});
  const [openModalVendas, setOpenModalVendas] = useState(false);

  function OpenModalVendededor(data) {
    setDataVendedorSelecionado(data);
    setOpenModalVendas(true);
  }

  return (
    <DashboardLayout>
      <Loading loading={loading} />
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Vendas Diárias
                  </MDTypography>
                </div>
              </MDBox>
              <Paper elevation={1} style={{ padding: 5 }}>
                Selecione o vendedor para incluir o fechamento diário:
                <Grid container spacing={1}>
                  {vendedores_totais &&
                    vendedores_totais?.map((vendedor, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          key={index.vendedor_id}
                        >
                          <div
                            style={{
                              border: "1px solid gray",
                              borderRadius: "15px 0px 15px 0px",
                              padding: 7,
                            }}
                          >
                            <h4
                              style={{
                                fontSize: 14,
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              {vendedor.vendedor_nome}
                            </h4>
                            <Grid
                              container
                              spacing={1}
                              height="100%"
                              style={{ fontSize: 12 }}
                            >
                              {vendedor?.vendas_dia?.map((dia) => {
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    key={index.vendedor_id}
                                    style={{ padding: 10, color: dia.total_linhas > 0 ? 'green' : '#090909' }}
                                  >
                                    • Produto: <b>{dia.fator_nome}</b> <br />•
                                    Quantidade: {dia.total_linhas}
                                    <br />• Receita:{" "}
                                    {toCurrency(dia.total_receita)}
                                  </Grid>
                                );
                              })}
                            </Grid>
                            <MDButton
                              variant="gradient"
                              color="info"
                              fullWidth
                              onClick={() => [OpenModalVendededor(vendedor), console.log('----->', vendedor)]}
                            >
                              Cadastrar
                            </MDButton>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              </Paper>
            </Card>
          </Grid>
        </Grid>
        <CadastrarVendas
          data={dataVendedorSelecionado}
          open={openModalVendas}
          close={() => setOpenModalVendas(false)}
        />
        {/* <OperadoraFatorCadastro
          open={openModalCadastro}
          close={() => setOpenModalCadastro(false)}
          updateList={GetDataLoad}
        />
        <OperadoraFatorEditar
          open={openModalEditar}
          close={() => setOpenModalEditar(false)}
          updateList={GetDataLoad}
          data={dataModalEditar}
          setData={setDataModalEditar}
        /> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VendasDiariasScreen;
