import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/status_pedidos";

export function ActionLoadStatusPedidos(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: true });
    http.get(`/api/v1/adm/status/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.STATUS_PEDIDOS_DATA, payload: response.data.status },
          { type: types.STATUS_PEDIDOS_DATA_PAGE, payload: response.data.page },
          { type: types.STATUS_PEDIDOS_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.STATUS_PEDIDOS_DATA_TOTAL, payload: response.data.total },
          { type: types.STATUS_PEDIDOS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadastrarStatus(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: true });
    http.post(`/api/v1/adm/status/cadastro`, data).then(
      (response) => {
        store.dispatch([{ type: types.STATUS_PEDIDOS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionAtualizarOperadora(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: true });
    http.put(`/api/v1/adm/operadora/atualizar`, data).then(
      (response) => {
        store.dispatch([{ type: types.STATUS_PEDIDOS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ListarCategoriasDisponiveisParaParceiro(query) {
  const qrt = customQueryStringify(query);
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: true });
    http.get(`/api/v1/adm/operadora/listar-parceiro?${qrt}`).then(
      (response) => {
        store.dispatch([{ type: types.STATUS_PEDIDOS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function CadastrarFunilGeral(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: true });
    http.post(`/api/v1/adm/status/atualizar-status`, data).then(
      (response) => {
        store.dispatch([
          { type: types.STATUS_PEDIDOS_LOADING, payload: false }
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.STATUS_PEDIDOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}