import * as types from "../types/operadora_fator";

const INITIAL_STATE = {
  loading: false,
  operadora_fator: [],
  operadora: {},
  page: 1,
  per_page: 10,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.OPERADORA_LOADING:
      return { ...state, loading: action.payload };

    case types.OPERADORA_DATA:
       return { ...state, operadora_fator: action.payload };

    case types.OPER_DATA:
       return { ...state,   operadora: action.payload };

       case types.OPERADORA_DATA_PAGE:
        return { ...state, page: action.payload };
  
      case types.OPERADORA_DATA_PER_PAGE:
        return { ...state, per_page: action.payload };
  
      case types.OPERADORA_DATA_TOTAL:
        return { ...state, total: action.payload };


    default:
      return state;
  }
};

