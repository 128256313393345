
import Dashboard from "layouts/dashboard";


import Icon from "@mui/material/Icon";

import SignIn from "layouts/authentication/sign-in";
import VendedoresApp from "layouts/vendedores";
import OperadoraFator from "./layouts/operadoraFator";
import VendasDiariasScreen from "layouts/vendasDiarias";
import StatusPedidoScreen from "layouts/statusPedidos";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  }, 
  {
    type: "collapse",
    name: "Vendedores",
    key: "vendedores",
    icon: <Icon fontSize="small">engineering</Icon>,
    route: "/vendedores",
    component: <VendedoresApp />,
  },
  {
    type: "collapse",
    name: "Vendas Diárias",
    key: "vendas-diarias",
    icon: <Icon fontSize="small">data_thresholding_outlined_icon</Icon>,
    route: "/vendas-diarias",
    component: <VendasDiariasScreen />,
  },
  {
    type: "collapse",
    name: "Operadora Fator",
    key: "OperadoraFator",
    icon: <Icon fontSize="small">percent</Icon>,
    route: "/operadora",
    component: <OperadoraFator />,
  },
  {
    type: "collapse",
    name: "Status Pedidos",
    key: "StatusPedidos",
    icon: <Icon fontSize="small">pending_outlined_icon</Icon>,
    route: "/status-pedido",
    component: <StatusPedidoScreen />,
  },


  {
    type: "collapse",
    name: "Sair",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },

 

];

export default routes;
