import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/vendedores";

export function loadVendedores(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.VENDEDORES_LOADING, payload: true });
    http.get(`/api/v1/adm/vendedor/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.VENDEDORES_DATA, payload: response.data.vendedores },
          { type: types.VENDEDORES_DATA_PAGE, payload: response.data.page },
          { type: types.VENDEDORES_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.VENDEDORES_DATA_TOTAL, payload: response.data.total },
          { type: types.VENDEDORES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.VENDEDORES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadatrarVendedor(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.VENDEDORES_LOADING, payload: true });
    http.post(`/api/v1/adm/vendedor/cadastro`, data).then(
      (response) => {
        store.dispatch([{ type: types.VENDEDORES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.VENDEDORES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionAtualizarVendedor(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.VENDEDORES_LOADING, payload: true });
    http.put(`/api/v1/adm/vendedor/atualizar`, data).then(
      (response) => {
        store.dispatch([{ type: types.VENDEDORES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.VENDEDORES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ListarCategoriasDisponiveisParaParceiro(query) {
  const qrt = customQueryStringify(query);
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.VENDEDORES_LOADING, payload: true });
    http.get(`/api/v1/adm/vendedor/listar-parceiro?${qrt}`).then(
      (response) => {
        store.dispatch([{ type: types.VENDEDORES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.VENDEDORES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ListarTodosVendedores(query) {
  const qrt = customQueryStringify(query);
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.VENDEDORES_LOADING, payload: true });
    http.get(`/api/v1/adm/vendedor/listar/vendedores`).then(
      (response) => {

        console.log('action braba', response.data)
        store.dispatch([
          { type: types.VENDEDORES_TOTAIS_DATA, payload: response.data },
          { type: types.VENDEDORES_LOADING, payload: false }
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.VENDEDORES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function CadastrarFunilVendaDia(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.VENDEDORES_LOADING, payload: true });
    http.post(`/api/v1/adm/funil/cadastro`, data).then(
      (response) => {
        store.dispatch([
          { type: types.VENDEDORES_LOADING, payload: false }
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.VENDEDORES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}
