import * as types from "../types/vendedores";

const INITIAL_STATE = {
  loading: false,
  vendedores: [],
  vendedores_totais: [], 
  vendedor: {},
  page: 1,
  per_page: 10,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.VENDEDORES_LOADING:
      return { ...state, loading: action.payload };

    case types.VENDEDORES_DATA:
       return { ...state, vendedores: action.payload };

    case types.VENDEDORES_TOTAIS_DATA:
       return { ...state, vendedores_totais: action.payload };

    case types.VENDEDOR_DATA:
       return { ...state,   vendedor: action.payload };

       case types.VENDEDORES_DATA_PAGE:
        return { ...state, page: action.payload };
  
      case types.VENDEDORES_DATA_PER_PAGE:
        return { ...state, per_page: action.payload };
  
      case types.VENDEDORES_DATA_TOTAL:
        return { ...state, total: action.payload };


    default:
      return state;
  }
};

